// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  dibsCheckoutUrl: 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1',
  firebase: {
    apiKey: 'AIzaSyDp1IP83ZjhX1c13fUqZda88WYWWZcRg0s',
    appId: '1:424925011673:web:dda8c3ee689682fa',
    authDomain: 'split-test-1.firebaseapp.com',
    databaseURL: 'https://split-test-1.firebaseio.com',
    measurementId: 'G-FPS65V51QT',
    messagingSenderId: '424925011673',
    projectId: 'split-test-1',
    storageBucket: '',
  },
  functionsUrl: 'https://us-central1-split-test-1.cloudfunctions.net/',
  functionsUrlEU: 'https://europe-west3-split-test-1.cloudfunctions.net/',
  globalsID: 'lHWzjXutxy8VvyytHVh9',
  orderbird: {
    myObUrl: 'https://staging-myob.orderbird.com/',
  },
  payment: {
    paypal: {
      clientId:
        'AUkDQFg5mFFDxj5VzCMtirbvX-VwelGJ_jUmVHYM_qpv0Djs3KNEg9EoRTM0CXYtTeaFDVNc5ADhv-QJ',
    },
  },
  production: false,
  reCaptcha3Key: '6Ld3S6wqAAAAAMFkeZIKWYHOXCLlNveyVsdw_SxO',
  stripePK: 'pk_test_yDzAbQwZZIFXwZxacICWYI8S00IN3MtoTA',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
