import { Injectable } from '@angular/core'
import { AlertController, LoadingController } from '@ionic/angular'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {}

  public async confirmAlert(
    header: string,
    message: string
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      buttons: [
        {
          handler: () => {
            alert.dismiss()
          },
          text: 'Okay',
        },
      ],
      header: header,
      message: message,
    })

    alert.present()
    return alert
  }

  public async errorAlert(
    errorMessage: string,
    errorHeader = 'Fehler',
    subHeader = ''
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      buttons: [
        {
          handler: () => {
            alert.dismiss()
          },
          text: 'Okay',
        },
      ],
      cssClass: 'paymentErrorModal',
      header: errorHeader,
      message: errorMessage,
      subHeader: subHeader,
    })

    alert.present()
    return alert
  }
  /**
   * Creates and returns a Promise that resolves to an Ionic Loading Controller element with the specified message.
   *
   * @param message - The message to be displayed on the loading spinner.
   * @returns A Promise that resolves to an HTMLIonLoadingElement representing the created loading spinner.
   */

  public createLoading(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingController.create({
      backdropDismiss: false,
      cssClass: 'custom-loading',
      message: message,
      spinner: null,
    })
  }
  /**
   * Creates the alert for the payment proccess(first instance)
   * @param htmlMessage the message that the alert should display accepts html
   */
  createLoadingForPayment(htmlMessage: string) {
    Swal.fire({
      allowOutsideClick: false,
      customClass: {
        popup: 'custom-loading-payment-swal', // Apply custom CSS classes to modal
      },
      heightAuto: false,
      html: `<div class="loading-wrapper">
          			<div class="loading-content spinner">
            			<p>${htmlMessage}</p>
          			</div>
        		</div>`,

      // Use the custom HTML for message and GIF
      showConfirmButton: false, // Hide the default confirm button
    })
  }

  /**
   * Updates the alert for the payment proccess(next instance)
   * @param htmlMessage the message that the alert should display accepts html
   */
  updateLoadingForPayment(htmlMessage: string) {
    Swal.update({
      html: `<div class="loading-wrapper">
          			<div class="loading-content spinner">
            			<p class="fade-text">${htmlMessage}</p>
          			</div>
        		</div>`,
    })
    // Triggering the fade-in animation after updating
    const fadeTextElement = document.querySelector('.fade-text') as HTMLElement
    if (fadeTextElement) {
      fadeTextElement.classList.remove('fade-in') // Remove the fade-in class if it exists
      void fadeTextElement.offsetWidth // Trigger reflow to restart animation
      fadeTextElement.classList.add('fade-in') // Add the fade-in class to start the animation
    }
  }
  /**
   * Updates the alert for the payment proccess(final instance)
   * @param htmlMessage the message that the alert should display accepts html
   */
  updateLoadingForPaymentSuccess(htmlMessage: string) {
    Swal.update({
      html: `<div class="loading-wrapper">
          			<div class="loading-content success">
            			<p class="fade-text">${htmlMessage}</p>
          			</div>
        		</div>`,
    })
    // Triggering the fade-in animation after updating
    const fadeTextElement = document.querySelector('.fade-text') as HTMLElement
    if (fadeTextElement) {
      fadeTextElement.classList.remove('fade-in')
      void fadeTextElement.offsetWidth
      fadeTextElement.classList.add('fade-in')
    }
    setTimeout(() => {
      Swal.close()
    }, 3000)
  }
}
