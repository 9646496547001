import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Router } from '@angular/router'
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular'
import { loadScript } from '@paypal/paypal-js'
import { OrderResponse } from 'functions/src/ordering/types'
import * as $ from 'jquery'
import { ProfileEntryModalPage } from 'src/app/profile-entry-modal/profile-entry-modal.page'
import { environment } from 'src/environments/environment'
import {
  Gastro,
  PaymentId,
  PaymentOption,
} from 'src/shared/split-submodules/types/types'
import { AlertService } from './alert.service'
import { OrderbirdService } from './api/orderbird/orderbird.service'
import { CartService } from './cart.service'
import { DeviceService } from './device.service'
import { GastroService } from './gastro.service'
import { GeoService } from './geo.service'
import { GastroResettable, ResetService } from './reset.service'
import { ScriptLoadingService } from './script-loading.service'
import { SentryService } from './sentry.service'
import { SessionDataService } from './session-data.service'
import { StorageService } from './storage.service'
import { TableService } from './table.service'
import { UserService } from './user.service'
import { UtilService } from './util.service'

declare let Stripe

export interface Discount {
  days?: boolean[]
  delivery?: boolean
  fix: number
  from: string
  to: string
  inhouse?: boolean
  name: string
  percentage: number
  pickup?: boolean
  isWithinTime?: boolean
}

@Injectable({ providedIn: 'root' })
export class PaymentService implements GastroResettable {
  public stripe
  private paymentId: PaymentId = undefined
  private paymentName = ''
  private paymentOption: PaymentOption
  private invoiceNumber: string
  private didMobilePayment = false
  public paymentIntent
  private paymentInProgress = false
  private card: any
  private oldPlatformValue = 0
  public paypalInit = false
  couponCode = ''
  userLoggedInCustomPay = true
  validation = false
  public dibsPaymentId = ''

  private baseUrl = 'https://www.paypal.com/sdk/js'

  //CLEANUP: ???
  invoice = false
  paypal = undefined

  savedPaymentOption: any = ''

  public set $paymentInProgress(newValue: boolean) {
    this.paymentInProgress = newValue
  }

  public get $paymentInProgress(): boolean {
    return this.paymentInProgress
  }

  public get $card(): any {
    return this.card
  }

  private payedLastOrder = false

  //CLEANUP: CartPage
  public tipButtons = [
    {
      selected: false,
      tipFix: 0.0,
      tipPerc: 0.0,
    },
    {
      selected: false,
      tipFix: 0.0,
      tipPerc: 0.05,
    },
    {
      selected: false,
      tipFix: 0.0,
      tipPerc: 0.1,
    },
    {
      selected: false,
      tipFix: 0.0,
      tipPerc: 0.15,
    },
    {
      selected: false,
      tipFix: 0.0,
      tipPerc: 0.0,
    },
  ]

  //#region get/set
  //identifier ec/credit/bar

  initStripe() {
    this.stripe = Stripe(environment.stripePK)
  }

  public get $paymentId(): PaymentId {
    return this.paymentId
  }

  /**
   * Sets the paymentId if the id can be found in Gastro and also sets the corresponding paymentOption
   */
  public set $paymentId(id: PaymentId) {
    if (id === undefined) {
      this.paymentId = undefined
      this.paymentOption = undefined
      this.paymentName = ''
      return
    }

    const paymentOption = this.gastroService.$gastro.paymentOptions.find(
      (option: PaymentOption) => option.option === id
    )
    if (paymentOption === undefined) {
      return
    }

    this.paymentId = id
    this.$paymentOption = paymentOption
    this.paymentName = this.$paymentOption.name

    this.storageService.store('paymentId', id)
    this.storageService.store('paymentOption', paymentOption)
    this.storageService.store('paymentName', this.$paymentOption.name)
  }

  public get $invoiceNumber() {
    return this.invoiceNumber
  }

  public set $invoiceNumber(val) {
    this.invoiceNumber = val
    this.storageService.store('invNumber', val)
  }

  public get $paymentOption(): PaymentOption {
    return this.paymentOption
  }

  public set $paymentOption(option: PaymentOption) {
    this.paymentOption = option
  }
  public get $paymentName(): string {
    return this.paymentName
  }

  public set $paymentName(name: string) {
    this.paymentName = name
  }

  public get $didMobilePayment(): boolean {
    return this.didMobilePayment
  }

  public set $didMobilePayment(newValue: boolean) {
    this.didMobilePayment = newValue
    this.storageService.store('didMobilePayment', newValue)
  }

  public get $payedLastOrder(): boolean {
    return this.payedLastOrder
  }

  public set $payedLastOrder(newValue: boolean) {
    this.payedLastOrder = newValue
  }

  //#endregion get/set

  constructor(
    private gastroService: GastroService,
    private cartService: CartService,
    private sessionDataService: SessionDataService,
    private geoService: GeoService,
    private afs: AngularFirestore,
    private tableService: TableService,
    private deviceService: DeviceService,
    private alertController: AlertController,
    private userService: UserService,
    private utilService: UtilService,
    private loadingCtrl: LoadingController,
    private storageService: StorageService,
    private modalController: ModalController,
    public router: Router,
    private resetService: ResetService,
    private sentryService: SentryService,
    private scriptLoadingService: ScriptLoadingService,
    private orderbirdService: OrderbirdService,
    private alertService: AlertService
  ) {
    this.registerGastroReset()
    this.initLoadingWindow()
    this.storageService.load('didMobilePayment').then((val) => {
      if (val != null) {
        this.didMobilePayment = val
      }
    })
    this.storageService.load('paymentId').then((val) => {
      if (val) {
        this.paymentId = val
      }
    })
    this.storageService.load('paymentOption').then((val) => {
      if (val) {
        this.paymentOption = val
      }
    })
    this.storageService.load('paymentName').then((val) => {
      if (val) {
        this.paymentName = val
      }
    })
    this.storageService.load('invNumber').then((val) => {
      if (val) {
        this.invoiceNumber = val
      }
    })
  }

  async initLoadingWindow() {
    this.utilService.loadingWindow = await this.alertService.createLoading(
      'Bestellung wird übermittelt'
    )
  }

  onGastroLogout(): void {
    this.$didMobilePayment = false
    this.$paymentId = undefined
    this.oldPlatformValue = 0
    this.invoiceNumber = ''
  }

  registerGastroReset(): void {
    this.resetService.registerGastroReset(this)
  }

  /**
   * this method preselects a payment method
   * if just one is visible in the current location
   */
  selectPaymentMethodIfjustOneIsVisible() {
    let counter = 0
    let indexPaymentOption = -1
    this.gastroService.$gastro?.paymentOptions?.forEach((method, index) => {
      if (
        method.inhouse === true &&
        this.sessionDataService.$inhouseLink === true
      ) {
        counter++
        indexPaymentOption = index
      }

      if (
        method.outerhouse === true &&
        this.sessionDataService.$inhouseLink === false
      ) {
        counter++
        indexPaymentOption = index
      }
    })

    if (counter == 1) {
      this.$paymentId =
        this.gastroService.$gastro?.paymentOptions[indexPaymentOption].option
    }
  }

  //CLEANUP: PaymentService
  mobilePaymentOnly() {
    let ret = true
    this.gastroService.$gastro.paymentOptions.forEach((option) => {
      if (option.option == 'bar' || option.option == 'ec-device') {
        ret = false
      }
    })
    return ret
  }

  //CLEANUP: PaymentService
  mobilePaySecure() {
    if (
      this.mobilePaymentOnly() &&
      this.gastroService.$gastro.mobilePaySecure
    ) {
      if (this.$payedLastOrder) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  //CLEANUP: utilService/splitsubmodule
  //calc brutto stripe fee - maybe change to netto in future
  calcStripeFee(total: number) {
    let fixedFee = 0.0
    let percentualFee = 0.0
    let splitFee = 0.0
    if (this.gastroService.$gastro.stripeFee !== undefined) {
      fixedFee = this.gastroService.$gastro.stripeFee.fixed
      percentualFee = this.gastroService.$gastro.stripeFee.percentual
    }

    splitFee = fixedFee + total * 100 * percentualFee
    if (
      this.gastroService.$gastro.country == undefined ||
      this.gastroService.$gastro.country == 'DE'
    ) {
      splitFee = splitFee * 1.19
    }
    splitFee = Math.ceil(splitFee)
    splitFee = splitFee / 100
    splitFee = splitFee + this.calcSplitCashFee(total)
    return splitFee
  }

  //calc brutto split fee (only for stripe) - maybe change to netto in future
  calcSplitCashFee(total: number) {
    let fixedFee = 0.0
    let percentualFee = 0.0
    if (this.gastroService.$gastro.splitFee != undefined) {
      fixedFee = this.gastroService.$gastro.splitFee.fixed
      percentualFee = this.gastroService.$gastro.splitFee.percentual
    }

    let fee = fixedFee + total * 100 * percentualFee
    if (
      this.gastroService.$gastro.country == undefined ||
      this.gastroService.$gastro.country == 'DE'
    ) {
      fee = fee * 1.19
    }
    fee = Math.ceil(fee)
    fee = fee / 100
    return fee
  }

  //paymentService
  async setupPlatformPay(
    didCheck: boolean,
    onSuccessCallback,
    total: number,
    totalBeforeDeliveryFee,
    preFlightCallBack
  ) {
    if (didCheck === false) {
      return
    }
    const amount = Math.round(Math.round(total * 100 * 100) / 100)
    const paymentRequest = this.stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `Bestellung bei ${this.gastroService.$gastro.name}`,
        amount: amount,
      },
    })
    const elements = this.stripe.elements()
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
    })

    // Check the availability of the Payment Request API first.
    const result = await paymentRequest.canMakePayment()
    if (result) {
      prButton.mount('#payment-request-button')
    } else {
      const prb = document.getElementById('payment-request-button')
      if (prb) {
        prb.style.display = 'none'
      }
    }

    this.payWithPlatform(
      paymentRequest,
      onSuccessCallback,
      total,
      totalBeforeDeliveryFee,
      preFlightCallBack
    )
  }

  /**
   * this is the function which handels the platform payment like google or apple pay
   * it calls the onSuccessCallback when finished with charging the amount of money
   * @param paymentRequest
   * @param onSuccessCallback
   * @param total
   * @param totalBeforeDeliveryFee
   */
  payWithPlatform(
    paymentRequest,
    onSuccessCallback,
    total,
    totalBeforeDeliveryFee,
    preFlightCallBack
  ) {
    paymentRequest.on('paymentmethod', async (ev) => {
      if (!(await this.verifySubmitForAllPaymentMethods(total, true))) {
        ev.complete('fail')
        return
      }
      if (
        (this.sessionDataService.$isToGo &&
          this.gastroService.$gastro.mobileNumberRequired == true &&
          !this.sessionDataService.$isDelivery) ||
        (this.sessionDataService.$inhouseLink &&
          this.gastroService.$gastro.sms &&
          this.gastroService.$gastro.selfService)
      ) {
        this.sessionDataService.$deliveryInformations = {
          mobileNr: this.userService.user.mobileNr,
        }
      }
      if (
        this.sessionDataService.$isDelivery != true &&
        this.gastroService.$gastro.nicknameRequired == true &&
        (this.sessionDataService.$nickname == '' ||
          this.sessionDataService.$nickname == undefined ||
          this.sessionDataService.$nickname.length == 0)
      ) {
        this.utilService.alertInfo(
          'Fehlender Name',
          'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
        )
        ev.complete('fail')
        return
      }
      if (
        (await this.validateDelivery(
          this.userService.user.name,
          this.userService.user.address.PLZ,
          this.userService.user.address.street,
          this.userService.user.address.houseNr,
          totalBeforeDeliveryFee
        )) &&
        (this.reachedMinOrderAmount('bottom', totalBeforeDeliveryFee) ||
          this.sessionDataService.$inhouse)
      ) {
        if ((await preFlightCallBack()) === false) {
          this.utilService.alertInfo(
            'Fehler',
            'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
          )
          paymentRequest.hide()
          ev.complete('fail')
          return false
        }
        // Confirm the PaymentIntent without handling potential next actions (yet).
        this.stripe
          .confirmCardPayment(
            this.paymentIntent.client_secret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          )
          .then(async (confirmResult) => {
            if (confirmResult.error) {
              const alert = await this.alertController.create({
                buttons: [{ text: 'Ok' }],
                header: 'Fehler',
                message: confirmResult.error.code,
                subHeader: `Es gab einen Fehler mit Deiner Karte. 
							Überprüfe bitte die Daten und versuche es nochmal!`,
              })
              ev.complete('fail')
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete('success')
              //TODO - this line could may prevent payments without an ordr beeing send
              //but we need to test this one
              //if (confirmResult.paymentIntent.status === "requires_action") {
              this.stripe
                .confirmCardPayment(this.paymentIntent.client_secret)
                .then(async (result) => {
                  if (result.error) {
                    const alert = await this.alertController.create({
                      buttons: [{ text: 'Ok' }],
                      header: 'Fehler',
                      message: result.error.code,
                      subHeader: `Es gab einen Fehler mit Deiner Karte. 
									Überprüfe bitte die Daten und versuche es nochmal!`,
                    })
                  } else {
                    this.handlePayment(result, onSuccessCallback)
                  }
                })
            }
          })
      } else {
        ev.complete('fail')
      }
    })
  }

  // eslint-disable-next-line max-lines-per-function
  async setupPayPal(
    totalFunc,
    onSuccessCallback,
    totalBeforeDeliveryFunc,
    preFlightCallBack,
    useCloudCheckoutFunction,
    cloudCheckoutFunction,
    cloudCheckoutFinalizeFunction
  ) {
    if (this.paypal !== undefined && this.paypal !== null) {
      return
    }
    try {
      this.paypal = await loadScript({
        clientId: environment.payment.paypal.clientId,
        commit: false,
        currency: 'EUR',
        disableFunding: 'credit,card,sepa',
      })
    } catch (error) {
      console.error('failed to load the PayPal JS SDK script', error)
    }
    let didUseCloudCheckout = false
    let orderResponse: OrderResponse
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      this.paypal
        .Buttons({
          commit: false, // Show a 'Pay Now' button
          // Set up the transaction
          createOrder: async (data, actions) => {
            if (useCloudCheckoutFunction()) {
              try {
                orderResponse = await cloudCheckoutFunction()
                didUseCloudCheckout = true
                if (orderResponse.paymentData.provider !== 'paypal') {
                  throw new Error('Unexpected return value')
                }
              } catch (err) {
                // TODO: Paypal behaves weird or throws an uncaught error when this function does not return an orderID. We have to find out how to handle errors during createOrder, as PayPal does not provide any documentation for this case
                return err
              }
              return orderResponse.paymentData.data.id
            }
            return this.payPalCreateOrderWithLegacy(
              actions,
              totalFunc,
              totalBeforeDeliveryFunc,
              preFlightCallBack
            )
          },
          // Finalize the transaction
          onApprove: (data, actions) => {
            if (didUseCloudCheckout) {
              return this.payPalOnApproveWithCloudCheckout(
                data,
                orderResponse,
                cloudCheckoutFinalizeFunction
              ).catch((err) => {
                return
              })
            } else {
              return this.payPalOnApproveWithLegacy(actions, onSuccessCallback)
            }
          },
          onCancel: (err) => {
            this.utilService.loadingWindowDismiss()
          },
          onError: (err) => {
            this.utilService.loadingWindowDismiss()
            console.error(err)
            this.sentryService.captureError('Afterflight error paypal 3', '')
          },
          style: {
            color: 'blue',
            height: 40,
            label: 'pay',
            shape: 'pill',
          },
        })
        .render('#paypal-button')
    }, 1000)
  }

  private async payPalCreateOrderWithLegacy(
    actions,
    totalFunc,
    totalBeforeDeliveryFunc,
    preFlightCallBack
  ) {
    const total = totalFunc()
    if (!(await this.verifySubmitForAllPaymentMethods(total, true))) {
      return
    }
    if (
      (this.sessionDataService.$isToGo &&
        this.gastroService.$gastro.mobileNumberRequired == true &&
        !this.sessionDataService.$isDelivery) ||
      (this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.sms &&
        this.gastroService.$gastro.selfService)
    ) {
      this.sessionDataService.$deliveryInformations = {
        mobileNr: this.userService.user.mobileNr,
      }
    }

    if (
      this.sessionDataService.$inhouseLink == true &&
      this.gastroService.$gastro.nicknameRequired == true &&
      (this.sessionDataService.$nickname == '' ||
        this.sessionDataService.$nickname == undefined ||
        this.sessionDataService.$nickname.length == 0)
    ) {
      this.utilService.alertInfo(
        'Fehlender Name',
        'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
      )
      return
    }
    if (
      this.sessionDataService.$inhouse &&
      this.tableService.getTable() === undefined
    ) {
      this.utilService.alertInfo(
        'Tisch ungültig ',
        'Sag uns bitte an welchem Tisch du sitzt'
      )
      return
    }
    if (
      await this.validateDelivery(
        this.userService.user.name,
        this.userService.user.address.PLZ,
        this.userService.user.address.street,
        this.userService.user.address.houseNr,
        totalBeforeDeliveryFunc()
      )
    ) {
      const amount = total.toFixed(2)
      //secure payment
      const securedGastro = await this.afs
        .collection<Gastro>('gastro')
        .doc(this.gastroService.$gastroId)
        .get()
        .toPromise()
      const paypalID = securedGastro.data().paypalID
      if ((await preFlightCallBack()) === false) {
        this.utilService.alertInfo(
          'Fehler',
          'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
        )
        return
      }

      return actions.order.create({
        application_context: { shipping_preference: 'NO_SHIPPING' },
        currency: 'EUR',
        currencyIcon: '€',
        purchase_units: [
          {
            amount: {
              value: amount,
              // breakdown: {
              //     item_total: {
              //       currency_code: 'EUR',
              //   	value: this.cartService.getTotal
              //   	- this.cartService.getUmsatzsteuer()
              //     },
              //     tax_total: {
              //       currency_code: 'EUR',
              //       value: this.cartService.getUmsatzsteuer()
              //     },
              // }
            },
            currency: 'EUR',
            currencyIcon: '€',
            payee: { email_address: paypalID },
          },
        ],
      })
    }
  }

  /**
   * This function is called by payPal once the createOrder function was successful and the order was made via cloud-checkout
   *
   * @param data
   * @param response
   * @param cloudCheckoutFinalizeFunction
   * @returns
   */
  private async payPalOnApproveWithCloudCheckout(
    data,
    response: OrderResponse,
    cloudCheckoutFinalizeFunction
  ) {
    const url = `${environment.functionsUrl}paypalOrderOnApprove`

    const settings = {
      contentType: 'application/json',
      data: JSON.stringify({
        payPalOrderID: data.orderID,
        gastroId: this.gastroService.$gastroId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      type: 'POST',
      url: url,
    }

    let shouldDisplayLoading = true
    let loading: HTMLIonLoadingElement
    this.loadingCtrl
      .create({ message: 'Zahlung wird bearbeitet...' })
      .then((loadingElement) => {
        loading = loadingElement
        if (shouldDisplayLoading) {
          loadingElement.present()
        }
      })

    await $.ajax(settings)
      .always(() => {
        shouldDisplayLoading = false
        if (loading) {
          loading.dismiss()
        }
      })
      .done(() => {
        // Payment was successful
        cloudCheckoutFinalizeFunction(response)
      })
      .fail(async (e) => {
        // Payment was declined
        const alert = await this.alertController.create({
          buttons: [{ text: 'Ok' }],
          header: 'Zahlungsfehler',
          message:
            'Es gab einen ' +
            'Fehler bei der Zahlung. Bitte versuche es erneut oder wähle eine andere Zahlungsmethode',
        })
        alert.present()
        console.log('PayPal payment was declined')
      })
    return
  }

  /**
   * This function is called by payPal once the createOrder function was successful and the order was made with the legacy checkout
   *
   * @param actions the paypal actions
   * @param onSuccessCallback the callback to be called once the payment was successfully captured
   */
  private async payPalOnApproveWithLegacy(actions, onSuccessCallback) {
    actions.order
      .capture()
      .then(async (details) => {
        this.$payedLastOrder = true
        this.paypalInit = false
        this.$didMobilePayment = true
        // Show a success message to the buyer
        // Show loading window only when orderbird is active
        if ((await this.orderbirdService.active) === true) {
          this.utilService.loadingWindow =
            await this.alertService.createLoading('Bestellung wird übermittelt')
          this.utilService.loadingWindow.present()
        }
        onSuccessCallback()
        // this.checkout();
      })
      .catch((err) => {
        console.error(err)
        this.utilService.loadingWindowDismiss()
        this.sentryService.captureError('Afterflight error paypal 1', '')
      })
  }

  async handlePayment(paymentResponse, onSuccessCallback) {
    const { paymentIntent, error } = paymentResponse

    if (error) {
      const alert = await this.alertController.create({
        buttons: [{ text: 'Ok' }],
        header: 'Fehler',
        message: error.error.code,
        subHeader:
          'Es gab einen Fehler mit Deiner Karte. Überprüfe bitte die Daten und versuche es nochmal!',
      })
    } else if (paymentIntent.status === 'succeeded') {
      this.$payedLastOrder = true
      this.couponCode = ''
      this.$didMobilePayment = true
      this.utilService.loadingWindow = await this.alertService.createLoading(
        'Bestellung wird übermittelt'
      )
      this.utilService.loadingWindow?.present()
      onSuccessCallback()
      // this.checkout();
    } else {
      // Payment has failed.
    }
  }

  async validateDelivery(
    fullName,
    plz,
    street,
    streetNr,
    totalBeforeDeliveryFee
  ) {
    let isValid = true
    if (
      this.sessionDataService.$isDelivery &&
      this.gastroService.$gastro.hasDelivery
    ) {
      this.validation = true
      if (
        fullName.length < 3 ||
        plz.length != 5 ||
        street.length < 2 ||
        streetNr == ''
      ) {
        isValid = false
      }

      if (this.utilService.checkMobileNr() == false) {
        this.utilService.alertInfo(
          'Es gab einen Fehler mit deiner Telefonnummer',
          'Kontrolliere bitte deine Telefonnummer',
          '#handynummer'
        )
        isValid = false
      }

      if (isValid) {
        this.$paymentInProgress = true
        this.validation = false
        //dismiss window before showing new loading window
        await this.utilService.loadingWindowDismiss()
        this.utilService.loadingWindow = await this.loadingCtrl.create({
          message: 'Adresse wird überprüft',
        })
        this.utilService.loadingWindow?.present()
        isValid = await this.geoService.checkDistance(totalBeforeDeliveryFee)
        this.utilService.loadingWindowDismiss()
        this.$paymentInProgress = false
      }
    }
    return isValid
  }

  public codeToUppercase() {
    this.couponCode = this.couponCode.toUpperCase()
  }

  elements

  setupStripe(
    totalFunc: any,
    successCallback,
    totalBeforeDeliveryFeeFunc,
    preFlightCallBack,
    useCloudCheckoutFunction: any
  ) {
    this.elements = this.stripe.elements()

    this.setupStripeCard(
      totalFunc,
      successCallback,
      totalBeforeDeliveryFeeFunc,
      preFlightCallBack,
      useCloudCheckoutFunction
    )
  }

  /**
   * Create and mount the element where the user can enter their card details
   * @param totalFunc The function to determine how much the total price is
   * @param successCallback The function to be called once the payment was successful
   * @param totalBeforeDeliveryFeeFunc The function to determine how much the total price is before delivery fee
   * @param preFlightCallBack The function to call before the payment is actually submitted
   * @param useCloudCheckoutFunction A function to determine whehter or not to use the cloud-checkout
   */
  private setupStripeCard(
    totalFunc: any,
    successCallback,
    totalBeforeDeliveryFeeFunc,
    preFlightCallBack,
    useCloudCheckoutFunction: any
  ) {
    const style = {
      base: {
        '::placeholder': { color: '#aab7c4' },
        color: '#32325d',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '17px',
        fontSmoothing: 'antialiased',
        fontWeight: 400,
        lineHeight: '56px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }

    this.card = this.elements.create('card', {
      style: style,
      hidePostalCode: true,
    })

    this.card.mount('#card-element')

    this.card.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    const form = document.getElementById('payment-form')
    form.addEventListener('submit', async (event) => {
      this.stripeOnSubmit(
        event,
        totalFunc,
        successCallback,
        totalBeforeDeliveryFeeFunc,
        preFlightCallBack,
        useCloudCheckoutFunction
      )
    })
  }

  /**
   * The function to be called once the payment is being submitted
   * @param event the submit event
   * @param totalFunc The function to determine how much the total price is
   * @param successCallback The function to be called once the payment was successful
   * @param totalBeforeDeliveryFeeFunc The function to determine how much the total price is before delivery fee
   * @param preFlightCallBack The function to call before the payment is actually submitted
   * @param useCloudCheckoutFunction A function to determine whehter or not to use the cloud-checkout
   * @returns
   */
  private async stripeOnSubmit(
    event,
    totalFunc: any,
    successCallback,
    totalBeforeDeliveryFeeFunc,
    preFlightCallBack,
    useCloudCheckoutFunction: any
  ) {
    event.preventDefault()

    if (useCloudCheckoutFunction()) {
      return
    }
    if ((await preFlightCallBack()) === false) {
      this.utilService.alertInfo(
        'Fehler',
        'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
      )
      return
    }
    if (
      await this.validateDelivery(
        this.userService.user.name,
        this.userService.user.address.PLZ,
        this.userService.user.address.street,
        this.userService.user.address.houseNr,
        totalBeforeDeliveryFeeFunc()
      )
    ) {
      const total = totalFunc()
      this.paymentInProgress = true

      this.utilService.loadingWindow = await this.loadingCtrl.create({
        message: 'Zahlung wird überprüft',
      })
      this.utilService.loadingWindow?.present()
      if (this.userLoggedInCustomPay == true) {
        this.stripe.createToken(this.card).then((result) => {
          if (result.error) {
            this.utilService.alertInfo(
              'Kredtikarte',
              'Die angegebenen Kreditkarteninformationen enthalten Fehler, oder sind nicht vollständig',
              '#askHowToPayItem'
            )
            this.utilService.loadingWindowDismiss()
            this.paymentInProgress = false
            const errorElement = document.getElementById('card-errors')
            errorElement.textContent = result.error.message
          } else {
            this.makePayment2(total, successCallback)
            this.paymentInProgress = false
          }
        })
      } else if (
        this.userService.profile.isLoggedIn == true &&
        this.userLoggedInCustomPay == false
      ) {
        this.makePayment2(total, successCallback)
        this.paymentInProgress = false
      }
    } else {
      throw {
        name: 'ValidationError',
        message: 'the programmcode should not go in here. l740',
      }
    }
  }

  makePaymentIntent(total: number) {
    return new Promise<void>((resolve, reject) => {
      if (this.oldPlatformValue != total) {
        this.oldPlatformValue = total
        const amount = Math.round(
          (this.oldPlatformValue + Number.EPSILON) * 100
        )
        const data = {
          amount: amount,
          currency: 'EUR',
          gastroID: this.gastroService.$gastroId,
        }
        const url = `${environment.functionsUrl}payWithStripeV3`
        $.post(url, data, async (e) => {
          this.paymentIntent = e
          resolve()
        }).always(() => {
          reject()
        })
      }
      resolve()
    })
  }

  makePayment2(total, successCallback) {
    const amount = Math.round((total + Number.EPSILON) * 100)
    let data
    if (
      this.userService.profile.isLoggedIn == true &&
      this.userLoggedInCustomPay == false
    ) {
      data = {
        amount: amount,
        currency: 'EUR',
        customerID: this.userService.profile.stripeUser,
        gastroID: this.gastroService.$gastroId,
        paymentMethodID: this.savedPaymentOption.paymentID,
      }
    } else {
      data = {
        amount: amount,
        currency: 'EUR',
        gastroID: this.gastroService.$gastroId,
      }
    }
    const url = `${environment.functionsUrl}payWithStripeV3`
    $.post(url, data, async (e) => {
      this.verfiyStripe(e, successCallback)
    })
  }

  verfiyStripe(response, successCallback) {
    if (
      this.userService.profile.isLoggedIn == true &&
      this.userLoggedInCustomPay == false
    ) {
      {
        this.stripe
          .confirmCardPayment(response.client_secret, {
            payment_method: this.savedPaymentOption.paymentID,
          })
          .then(async (result) => {
            console.log(result.error)
            if (result.error) {
              this.paymentInProgress = false
              this.utilService.loadingWindowDismiss()
              const alert = await this.alertController.create({
                buttons: [{ text: 'Ok' }],
                header: 'Fehler',
                message: result.error.code,
                subHeader: `Es gab einen Fehler mit Deiner Karte. 
								Überprüfe bitte die Daten und versuche es nochmal!`,
              })

              await alert.present()
            } else {
              this.paymentInProgress = false
              this.payedLastOrder = true
              this.$didMobilePayment = true
              this.paymentIntent = response
              successCallback()
            }
          })
      }
    } else if (
      this.userService.profile.isLoggedIn == false ||
      (this.userService.profile.isLoggedIn == true &&
        this.userLoggedInCustomPay == true)
    ) {
      {
        this.stripe
          .confirmCardPayment(response.client_secret, {
            payment_method: { card: this.card },
          })
          .then(async (result) => {
            console.log(result.error)
            if (result.error) {
              this.paymentInProgress = false
              this.utilService.loadingWindowDismiss()
              const alert = await this.alertController.create({
                buttons: [{ text: 'Ok' }],
                header: 'Fehler',
                message: result.error.code,
                subHeader: `Es gab einen Fehler mit Deiner Karte. 
								Überprüfe bitte die Daten und versuche es nochmal!`,
              })

              await alert.present()
            } else {
              this.paymentInProgress = false
              this.$payedLastOrder = true
              this.$didMobilePayment = true
              this.paymentIntent = response
              successCallback()
            }
          })
      }
    } else {
      console.log('else')
    }
  }

  /**
   * this function has to be included in every payment
   * @param total
   * @param obAPIActive
   * @param isMobilePayment
   * @returns
   */
  async verifySubmitForAllPaymentMethods(total, isMobilePayment) {
    if (
      ((this.sessionDataService.$isToGo &&
        this.gastroService.$gastro.mobileNumberRequired == true &&
        !this.sessionDataService.$isDelivery) ||
        (this.gastroService.$gastro.selfService &&
          this.gastroService.$gastro.sms &&
          this.sessionDataService.$inhouseLink)) &&
      this.utilService.checkMobileNr() == false
    ) {
      this.utilService.alertInfo(
        'Deine Telefonnummer enthält Fehler',
        'Bitte überprüfe deine Telefonnummer erneut',
        '#handynummer'
      )
      return false
    }
    const option = this.$paymentOption
    if (
      option !== undefined &&
      option.minAmount !== undefined &&
      total < option.minAmount
    ) {
      this.utilService.alertInfo(
        'Mindestbestellwert nicht erreicht',
        `Um mit ${option.name} zu bestellen 
				muss mindestens ein Bestellwert von ${option.minAmount}€ erreicht werden."`,
        '#askHowToPayItem'
      )
      return false
    }
    //check if payment can be performed on orderbird
    //don't check for paypal because we then have a forever spinning paypal wheel
    //we check paypal on a earlier stage
    if (
      (await this.orderbirdService.active) === true &&
      isMobilePayment === true &&
      this.paymentId !== 'paypal'
    ) {
      const obCheck = await this.checkIfPaymentCanBePerformedOnOrderbird()
      if (obCheck === false) {
        this.utilService.alertInfo(
          'Tisch blockiert',
          'Der Tisch ist durch einen Kellner blockiert. ' +
            'Zu Deiner Sicherheit wurde die Bezahlung abgebrochen. ' +
            'Bitte wende Dich an einen Kellner um das Problem zu lösen.'
        )
        return false
      }
    }
    return true
  }

  async checkIfPaymentCanBePerformedOnOrderbird() {
    this.utilService.loadingWindow = await this.alertService.createLoading(
      'Bestellung wird überprüft'
    )
    this.utilService.loadingWindow?.present()
    try {
      const data = {
        gastroId: this.gastroService.$gastroId,
        table: {
          id: this.tableService.getTable().addData.ob_id,
          name: this.tableService.getTable().addData.name,
          nr: this.tableService.getTable().tischNR,
        },
      }
      const url = `${environment.functionsUrlEU}checkIfPaymentCanBePerformedOnOBTab`
      const ret = await $.post(url, data)
      this.utilService.loadingWindowDismiss()
      return ret
    } catch (error) {
      this.utilService.loadingWindowDismiss()
      return false
    }
  }

  async payBar(
    checkoutCallback,
    total,
    totalBeforeDeliveryFee,
    preFlightCallBack
  ) {
    this.paymentInProgress = true
    this.utilService.loadingWindow = await this.alertService.createLoading(
      'Bestellung wird übermittelt'
    )
    if (!(await this.verifySubmitForAllPaymentMethods(total, false))) {
      this.paymentInProgress = false
      return
    }
    //let errormsg = this.validCoronaInformation()
    //if (errormsg != "") {
    //     this.alertInfo("Es fehlen einige Daten um Infektionswege zurückverfolgen zu können: ", errormsg)
    //     return;
    //   }

    // if (this.sessionDataService.$inhouse
    // 	&& (
    // 		this.cartService.selectedTableNr == undefined
    // 		|| !this.cartService.tableValid
    // 	)
    // ) {
    //     this.alertInfo("Tisch ungültig ", "Sag uns bitte an welchem Tisch du sitzt")
    //     return
    // }
    if (
      this.sessionDataService.$isDelivery != true &&
      this.gastroService.$gastro.nicknameRequired == true &&
      (this.sessionDataService.$nickname == '' ||
        this.sessionDataService.$nickname == undefined ||
        this.sessionDataService.$nickname.length == 0)
    ) {
      this.utilService.alertInfo(
        'Fehlender Name',
        'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
      )
      this.paymentInProgress = false
      return
    }
    if (this.sessionDataService.$inhouse) {
      if ((await preFlightCallBack()) === false) {
        this.utilService.alertInfo(
          'Fehler',
          'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
        )
        this.paymentInProgress = false
        return
      }
      this.utilService.loadingWindow?.present()
      checkoutCallback()
    } else if (this.reachedMinOrderAmount('top', totalBeforeDeliveryFee)) {
      if (
        this.sessionDataService.$isToGo &&
        (this.$paymentId == 'bar' || this.$paymentId == 'ec-device')
      ) {
        this.validation = true

        if (this.utilService.checkMobileNr() == false) {
          this.utilService.alertInfo(
            'Deine Telefonnummer enthält fehler',
            'Bitte überprüfe deine Telefonnummer erneut',
            '#handynummer'
          )
          this.paymentInProgress = false
          return
        }

        if (this.utilService.checkMobileNr() == true) {
          this.validation = false
          this.sessionDataService.$deliveryInformations = {
            mobileNr: this.userService.user.mobileNr,
          }
          if ((await preFlightCallBack()) === false) {
            this.utilService.alertInfo(
              'Fehler',
              'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
            )
            this.paymentInProgress = false
            return
          }
          this.utilService.loadingWindow?.present()
          checkoutCallback()
        } else {
          console.log('else')
        }
      }
    }
    this.paymentInProgress = false
  }

  reachedMinOrderAmount(pos = 'top', totalBeforeDeliveryFee) {
    if (
      this.sessionDataService.$isDelivery &&
      this.gastroService.$gastro.deliveryOptions != undefined &&
      this.gastroService.$gastro.deliveryOptions.minOrderAmount != undefined &&
      this.gastroService.$gastro.deliveryOptions.minOrderAmount >
        totalBeforeDeliveryFee
    ) {
      this.utilService.presentToast(
        'Mindestbestellwert nicht erreicht',
        pos,
        '#Warenkorb'
      )
      return false
    } else {
      return true
    }
  }

  async submitInvoice(checkoutCallback, preFlightCallBack) {
    if (
      this.sessionDataService.$isDelivery != true &&
      this.gastroService.$gastro.nicknameRequired == true &&
      (this.sessionDataService.$nickname == '' ||
        this.sessionDataService.$nickname == undefined ||
        this.sessionDataService.$nickname.length == 0)
    ) {
      this.utilService.alertInfo(
        'Fehlender Name',
        'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
      )
      return
    }
    if (!this.sessionDataService.$nickname) {
      this.sessionDataService.$nickname = ''
    }
    this.utilService.loadingWindow = await this.loadingCtrl.create({
      message: 'Die E-Mail Adresse wird überprüft',
    })
    await this.utilService.loadingWindow?.present()
    this.userService.user.email = this.userService.user.email?.toLowerCase()
    const user = {
      email: this.userService.user.email,
      password: this.userService.user.email,
    }
    this.invoice = true

    if ((await preFlightCallBack()) === false) {
      this.utilService.alertInfo(
        'Fehler',
        'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
      )
      await this.utilService.loadingWindowDismiss()
      return
    }

    //TODO: Will this trigger when not ordering from vino?
    this.userService
      .checkIfVinoUserExists(this.userService.user.email)
      .then(async (vinoExists) => {
        if (vinoExists) {
          this.userService
            .checkUserExist(this.userService.user.email)
            .then((exists) => {
              if (exists) {
                if (
                  this.userService.profile.userID == '' ||
                  this.userService.profile.userID == undefined ||
                  (this.userService.user.email !=
                    this.userService.profile.email.toLowerCase() &&
                    this.userService.profile.email != '')
                ) {
                  // important if logged in user is another than the user card.
                  // this would book the order on a different user
                  if (
                    this.userService.profile.userID != '' &&
                    this.userService.profile.userID != undefined &&
                    this.userService.user.email !=
                      this.userService.profile.email.toLowerCase() &&
                    this.userService.profile.email != ''
                  ) {
                    this.userService.signOut()
                  }
                  this.userService
                    .signIn(user.email, user.password, true)
                    .then(() => {
                      this.utilService.loadingWindowDismiss()
                      checkoutCallback().then(() => {
                        this.invoice = false
                      })
                    })
                    .catch(async (e) => {
                      // this is for vino.
                      // if the first login attemp was falsy it might be a problem with the letter cases.
                      // here we try to fetch the password from the db and try that one again.
                      // if falsy again we print an error
                      const userQuery = await this.afs
                        .collection('customer-profiles', (ref) =>
                          ref.where('name', '==', this.userService.user.email)
                        )
                        .get()
                        .toPromise()
                      if (userQuery.empty === false) {
                        const userDoc: any = userQuery.docs[0].data()
                        user.password = userDoc.password
                        this.userService
                          .signIn(user.email, user.password, true)
                          .then(() => {
                            this.utilService.loadingWindowDismiss()
                            checkoutCallback().then(() => {
                              this.invoice = false
                            })
                          })
                          .catch((e) => {
                            this.utilService.loadingWindowDismiss()
                            this.invoice = false
                            this.ifAutologinVinoFailsDoManualLogin()
                          })
                      } else {
                        this.utilService.loadingWindowDismiss()
                        this.invoice = false
                        this.ifAutologinVinoFailsDoManualLogin()
                      }
                    })
                } else {
                  this.utilService.loadingWindowDismiss()
                  checkoutCallback().then(() => {
                    this.invoice = false
                  })
                }
              } else {
                this.utilService.loadingWindowDismiss()
                this.userService
                  .register('', '', user.email, user.password, true)
                  .then(() => {
                    checkoutCallback().then(() => {
                      this.invoice = false
                    })
                  })
              }
            })
            .catch((e) => {
              this.invoice = false
              this.utilService.loadingWindowDismiss()
              alert(e)
            })
        } else {
          this.invoice = false
          this.utilService.loadingWindowDismiss()
          const alert = await this.alertController.create({
            buttons: [{ text: 'Ok' }],
            header: 'Fehler',
            message: '',
            subHeader:
              'Diesen Nutzer gibt es leider nicht. Bitte versuche es erneut!',
          })
          await alert.present()
        }
      })

    // create an entry in DB with username and generated PW (this will be the username as well for the time being)
    //check if the username already exists, if not, crete the entry as described above, if it exists, ->"log in"

    //continue transaction?
  }

  async ifAutologinVinoFailsDoManualLogin() {
    const loginAlert = await this.alertController.create({
      buttons: [
        {
          text: 'Login',
          handler: () => {
            this.openProfileSelect()
          },
        },
        {
          text: 'Abbrechen',
          role: 'cancel',
        },
      ],
      header: 'Achtung',
      message: '',
      subHeader:
        'Du musst eingeloggt sein um auf dieser Kundenkarte zu bestellen.',
    })
    await loginAlert.present()
  }

  async openProfileSelect() {
    this.$card.unmount()
    const modal = await this.modalController.create({
      component: ProfileEntryModalPage,
      cssClass: 'auto-height',
    })
    modal.present()

    modal.onDidDismiss().then((data) => {
      this.$card.mount()
      if (this.userService.profile.isLoggedIn) {
        this.fillInUserInfo()
      }
    })
  }

  public fillInUserInfo() {
    this.userService.fillInUserInfoFromProfile()

    // Thsi is where the paymentMEthod is autofilled if the user has a favourite one
    if (this.userService.getFavPaymentMethod() != false) {
      const filterPaymentOptions = []
      this.gastroService.$gastro.paymentOptions.forEach((option, index) => {
        filterPaymentOptions.push(option)
      })
      if (
        (this.$paymentId == undefined &&
          this.savedPaymentOption == undefined) ||
        this.$paymentOption == undefined
      ) {
        this.userLoggedInCustomPay = false
        this.$paymentId = undefined
        for (const filterItem of filterPaymentOptions) {
          // console.log("filter", filterItem)

          if (
            filterItem.option == this.userService.getFavPaymentMethod().type
          ) {
            if (
              this.sessionDataService.$inhouseLink == true &&
              filterItem.inhouse == true
            ) {
              this.$paymentId = this.userService.getFavPaymentMethod().type
              this.radioGroupChange(
                this.userService.getFavPaymentMethod().type,
                true
              )
              this.savedPaymentOption = this.userService.getFavPaymentMethod()
            } else if (
              this.sessionDataService.$inhouseLink == false &&
              filterItem.outerhouse == true
            ) {
              this.$paymentId = this.userService.getFavPaymentMethod().type
              this.radioGroupChange(
                this.userService.getFavPaymentMethod().type,
                true
              )

              this.savedPaymentOption = this.userService.getFavPaymentMethod()
            }
          }
        }
      }
    }
  }

  async radioGroupChange(val, profilePaymentMethod?: boolean) {
    if (val == 'reset') {
      //reset all selected payment
      this.$paymentId = undefined
      return
    }
    if (
      val.option !== 'credit' &&
      val.option !== 'platform' &&
      val.option !== 'paypal' &&
      this.gastroService.$gastro.hasTipping === true
    ) {
      this.setTippingButton(0)
    }

    if (val.option == 'paypal') {
      if ((await this.orderbirdService.active) === true) {
        const obCheck = await this.checkIfPaymentCanBePerformedOnOrderbird()
        if (obCheck === false) {
          this.utilService.alertInfo(
            'Tisch blockiert',
            'Der Tisch ist durch einen Kellner blockiert. ' +
              'Zu Deiner Sicherheit wurde die Bezahlung abgebrochen. ' +
              'Bitte wende Dich an einen Kellner um das Problem zu lösen.'
          )
          return false
        }
      }
    }
    // if (
    //     (val.option == 'bar' || val.option == 'ec-device') &&
    //     this.gastroService.$gastro.geoFence
    // ) {
    //     //this.presentAlert(); anschalten für geofence
    //     // this.geoService.trackMe();
    // }
    if (profilePaymentMethod) {
      this.$paymentId = val
    } else {
      this.$paymentId = val.option
    }
  }

  isTippingbuttonAlreadySelected(): boolean {
    let selected = false
    for (const button of this.tipButtons) {
      if (button.selected === true) {
        selected = true
      }
    }
    return selected
  }

  /**
   * sets the tipping to the givin index and resets all other buttons to false
   * @param index
   */
  setTippingButton(index: number) {
    for (let i = 0; i < this.tipButtons.length; i++) {
      this.tipButtons[i].selected = false
    }
    this.tipButtons[index].selected = true
  }

  getTips(totalBeforeTip) {
    let tips = 0
    this.tipButtons.forEach((btn) => {
      if (btn.selected == true) {
        tips += btn.tipFix + totalBeforeTip * btn.tipPerc
      }
    })
    return Math.round((tips + Number.EPSILON) * 100) / 100
  }

  async submitStripe(total, totalBeforeDeliverFee) {
    if (!(await this.verifySubmitForAllPaymentMethods(total, true))) {
      return
    }
    if (
      (this.sessionDataService.$isToGo &&
        this.gastroService.$gastro.mobileNumberRequired == true &&
        !this.sessionDataService.$isDelivery) ||
      (this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.sms &&
        this.gastroService.$gastro.selfService)
    ) {
      this.sessionDataService.$deliveryInformations = {
        mobileNr: this.userService.user.mobileNr,
      }
    }
    //let errormsg = this.validCoronaInformation()
    //if (errormsg != "") {
    //  this.alertInfo("Es fehlen einige Daten um Infektionswege zurückverfolgen zu können: ", errormsg)
    //  return;
    //}

    if (
      this.sessionDataService.$isDelivery != true &&
      this.gastroService.$gastro.nicknameRequired == true &&
      (this.sessionDataService.$nickname == '' ||
        this.sessionDataService.$nickname == undefined ||
        this.sessionDataService.$nickname.length == 0)
    ) {
      this.utilService.alertInfo(
        'Fehlender Name',
        'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
      )
      return
    }
    if (
      this.sessionDataService.$inhouse &&
      this.tableService.getTable() === undefined
    ) {
      this.utilService.alertInfo(
        'Tisch ungültig ',
        'Sag uns bitte an welchem Tisch du sitzt'
      )
      return
    }
    if (
      this.reachedMinOrderAmount('top', totalBeforeDeliverFee) ||
      this.sessionDataService.$inhouse
    ) {
      //todo maybe spalt here if user is logged in and using saved card
      // make same checks as submitStripe

      $('#payment-form').find(':submit').click()
    }
  }

  /**
   * function to create a new PaymentMethod on the Stripe platform
   * requires an StripeCustomerID, a card Element and an optional fav
   * first, it creates a card Token, to see if the data is valid
   * second, it creates a SetupCardIntent,
   * which initiates the process of creating a new PaymentMethod on the Stripe side of things
   * third,
   * @param stripeCustomer
   * @param card
   * @param fav
   * @returns
   */
  async createNewPaymentMethodStripe(
    stripeCustomer: string,
    card: any,
    fav: boolean
  ) {
    this.userService.loadingWindow?.present()

    return this.stripe.createToken(card).then((token) => {
      if (token.error) {
        this.userService.loadingWindow.dismiss()
        return token.error
      } else {
        const url2 = `${environment.functionsUrl}setupCardIntentProfiles`
        return $.post(url2, { customerID: stripeCustomer }, async (intent) => {
          if (intent.error) {
            return intent.error
          } else {
            const clientSecret = intent.client_secret
            return this.stripe
              .confirmCardSetup(clientSecret, {
                payment_method: {
                  card: card,
                  billing_details: { name: 'test' },
                  metadata: { fav: false },
                },
              })
              .then(async (confirmResult) => {
                if (confirmResult.error != undefined) {
                  this.userService.loadingWindow.dismiss()
                  const alert = await this.alertController.create({
                    header: 'Fehler',
                    subHeader:
                      'Karte konnte nicht angelegt werden. Bitte probiere eine andere Karte.',

                    buttons: [{ text: 'Ok' }],
                  })

                  await alert.present()

                  confirmResult.error
                } else if (fav) {
                  await this.userService
                    .selectFavPaymentMethod(
                      stripeCustomer,
                      confirmResult.setupIntent.payment_method
                    )
                    .then((e) => {})
                  await this.userService
                    .fetchPaymentMethods(stripeCustomer)
                    .then((newList) => {
                      this.userService.loadingWindow.dismiss()
                      this.router.navigateByUrl('/profile-area/paymentMethods')
                    })
                } else {
                  await this.userService
                    .fetchPaymentMethods(stripeCustomer)
                    .then((newList) => {
                      this.userService.loadingWindow.dismiss()

                      this.router.navigateByUrl('/profile-area/paymentMethods')
                    })
                }
              })
          }
        })
      }
    })
  }
  /**
   * neccesary Stripe function to interact with the credit card html element
   * @returns the stripe card html element
   */
  public getStripeElement() {
    const elements = this.stripe.elements()
    const style = {
      base: {
        '::placeholder': { color: '#aab7c4' },
        color: '#32325d',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '17px',
        fontSmoothing: 'antialiased',
        fontWeight: 400,
        lineHeight: '56px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }
    return elements.create('card', { style: style })
  }

  /**
   * this function checks the payment name and changes it to bitte auswählen if no selection was taken
   */
  public setPaymentNameIfUnlear() {
    if (this.paymentName == '') {
      this.paymentName = 'Bitte auswählen'
    }
  }

  public isPayPaylButtonVisible() {
    return document.getElementById('paypal-button').hasChildNodes()
  }

  private getSplitCartItemsForDibs() {
    const splitItems = []
    for (const cartItem of this.cartService.$cart) {
      splitItems.push({
        count: cartItem.count,
        id: cartItem.id,
        kind: cartItem.kind,
        name: cartItem.name,
        price: cartItem.price,
      })
    }
    return splitItems
  }

  public async getDibsPaymentId(calcTotalCallback, calcDiscountCallback, tip) {
    const splitItems = this.getSplitCartItemsForDibs()
    let discountAmount = 0

    for (const item of this.cartService.$cart) {
      discountAmount += calcDiscountCallback(item.price * item.count)
    }

    const isSplitWebAdress = window.location.href.includes('web.')
      ? true
      : false
    const localDev = window.location.href.includes('localhost') ? true : false
    const data = {
      deliveryFee: this.geoService.getDeliveryFee(calcTotalCallback()),
      discount: discountAmount,
      gastroId: this.gastroService.$gastroId,
      isSplitWebAdress: isSplitWebAdress,
      localDev: localDev,
      splitItems: splitItems,
      tip: tip,
    }
    const url = `${environment.functionsUrl}dibsCreateOrder`
    const ret = await $.post(url, data)

    return ret
  }

  public async updateDibsPayment(
    calcTotalCallback,
    calcDiscountCallback,
    tip,
    paymentId
  ) {
    const splitItems = this.getSplitCartItemsForDibs()
    let discountAmount = 0

    for (const item of this.cartService.$cart) {
      discountAmount += calcDiscountCallback(item.price * item.count)
    }

    const data = {
      deliveryFee: this.geoService.getDeliveryFee(calcTotalCallback()),
      discount: discountAmount,
      gastroId: this.gastroService.$gastroId,
      inhouse: this.sessionDataService.$inhouse,
      paymentId: paymentId,
      splitItems: splitItems,
      tip: tip,
    }
    const url = `${environment.functionsUrl}updateDibsOrder`
    const ret = await $.post(url, data)
    return ret
  }

  public async updateDibsReference(paymentId, cartId) {
    const isSplitWebAdress = window.location.href.includes('web.')
      ? true
      : false
    const localDev = window.location.href.includes('localhost') ? true : false

    const data = {
      gastroId: this.gastroService.$gastroId,
      isSplitWebAdress: isSplitWebAdress,
      localDev: localDev,
      paymentId: paymentId,
      reference: cartId,
    }
    const url = `${environment.functionsUrl}updateDibsReference`
    const ret = await $.post(url, data)

    return ret
  }

  // eslint-disable-next-line max-len
  public async verifyAndSetCheckoutDataForDibsPayTransaction(
    total,
    totalBeforeDeliveryFee,
    preFlightCallBack
  ) {
    if (!(await this.verifySubmitForAllPaymentMethods(total, true))) {
      return false
    }
    if (
      (this.sessionDataService.$isToGo &&
        this.gastroService.$gastro.mobileNumberRequired == true &&
        !this.sessionDataService.$isDelivery) ||
      (this.sessionDataService.$inhouseLink &&
        this.gastroService.$gastro.sms &&
        this.gastroService.$gastro.selfService)
    ) {
      this.sessionDataService.$deliveryInformations = {
        mobileNr: this.userService.user.mobileNr,
      }
    }
    if (
      this.sessionDataService.$isDelivery != true &&
      this.gastroService.$gastro.nicknameRequired == true &&
      (this.sessionDataService.$nickname == '' ||
        this.sessionDataService.$nickname == undefined ||
        this.sessionDataService.$nickname.length == 0)
    ) {
      this.utilService.alertInfo(
        'Fehlender Name',
        'Verrate uns Deinen Namen, damit wir Deine Bestellung zuordnen können'
      )
      return false
    }
    if (
      (await this.validateDelivery(
        this.userService.user.name,
        this.userService.user.address.PLZ,
        this.userService.user.address.street,
        this.userService.user.address.houseNr,
        totalBeforeDeliveryFee
      )) &&
      (this.reachedMinOrderAmount('bottom', totalBeforeDeliveryFee) ||
        this.sessionDataService.$inhouse)
    ) {
      if ((await preFlightCallBack()) === false) {
        this.utilService.alertInfo(
          'Fehler',
          'Es gab einen Anwendungsfehler. Bitte lade die Seite neu und versuche es erneut.'
        )
        return false
      }
    } else {
      return false
    }
    return true
  }

  getPayPalUrl(): string {
    // eslint-disable-next-line
    return `${this.baseUrl}?client-id=${environment.payment.paypal.clientId}&currency=EUR&disable-funding=credit,card,sepa&commit=false`
  }

  registerScript(loaded: (payPalApi: any) => void): void {
    this.scriptLoadingService.registerScript(
      this.getPayPalUrl(),
      'paypal',
      loaded
    )
  }
}
