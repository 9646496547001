import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Router } from '@angular/router'
import { ajax } from 'jquery'
import { Subscription } from 'rxjs'
import { AlertService } from 'src/app/services/alert.service'
import { GastroService } from 'src/app/services/gastro.service'
import { PaymentService } from 'src/app/services/payment.service'
import { SentryService } from 'src/app/services/sentry.service'
import { TableService } from 'src/app/services/table.service'
import { TableContentService } from 'src/app/services/table-content.service'
import { environment } from 'src/environments/environment'
import { PaymentState } from 'src/shared/split-submodules/types/enums'
import Swal from 'sweetalert2'

export interface SelfCheckoutPaymentMethod {
  name: string
  label: string
  option: string
  selected: boolean
}
@Injectable({
  providedIn: 'root',
})
export class PayAtTableOrderService {
  invoiceLink: string

  /**
   * The Stripe paymentMethod is generated
   * when the user confirms with the paymentMethodModal.
   * It is no longer created in the function -> crateStripeRequestData
   */
  public paymentMethod
  public paymentDocumentSubscription: Subscription = new Subscription()
  private tipValue = { valueAsNumber: 0, valueAsString: '0.00' } // Initialize with '0.00'
  public get $tipValue() {
    return this.tipValue
  }
  public set $tipValue(value: any) {
    this.tipValue.valueAsNumber = value.valueAsNumber
    this.tipValue.valueAsString = value.valueAsString
  }

  constructor(
    private afs: AngularFirestore,
    private alertService: AlertService,
    private gastroService: GastroService,
    private paymentService: PaymentService,
    private sentryService: SentryService,
    private tableContentService: TableContentService,
    private tableService: TableService,
    private router: Router
  ) {}

  /**
   * Starts the selected payment flow for the selected positions
   * Alerts the user if no positions are selected
   * or no valid payment method is selected
   * @returns
   */
  public async pay(
    isPlatformPay = false,
    paymentMethodPlatform: unknown = undefined
  ) {
    if (this.tableContentService.selectedTableContent.value.length === 0) {
      this.alertService.errorAlert('Bitte wähle mindestens ein Produkt aus!')
      return false
    }
    if (
      (this.paymentService.$paymentId === 'credit' ||
        this.paymentService.$paymentId === 'platform') &&
      this.gastroService.$gastro.hasDibsPayment !== true
    ) {
      return await this.payWithStripe(isPlatformPay, paymentMethodPlatform)
    }
    this.alertService.errorAlert('Bitte wähle eine Zahlungsmethode aus!')
  }

  /**
   * Starts the payment flow when paying with stripe
   * @param isPlatformPay
   * determines if the payment is done with the platform payment method
   * @param paymentMethod
   * the payment method object if isPlatformPay is true
   * @returns
   */
  private async payWithStripe(isPlatformPay = false, paymentMethod: unknown) {
    this.alertService.createLoadingForPayment('Bitte warten')

    try {
      const data = await this.createStripeRequestData(
        isPlatformPay,
        paymentMethod
      )
      const url = `${environment.functionsUrlEU}payAtTableStripe`
      const settings = {
        contentType: 'application/json',
        data: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        type: 'POST',
        url: url,
      }

      return new Promise<boolean>((resolve) => {
        ajax(settings)
          .done(async (response: any) => {
            await this.handleStripePaymentIntent(response.paymentIntent)
            resolve(
              await this.subscribeToPaymentDocument(response.paymentDocId)
            )
          })
          .fail((error) => {
            this.alertService.errorAlert(error.responseText)
            resolve(false)
          })
      })
    } catch (e) {
      Swal.close()
      console.error(e)
      return false
    }
  }

  /**
   * Creates the data needed by the backend when paying with stripe
   * @param isPlatformPay
   * pass true if the payment is done with the platform payment method
   * @param paymentMethodPlatform
   * the payment method object if isPlatformPay is true
   * @returns Promise<any>
   */
  private async createStripeRequestData(
    isPlatformPay: boolean,
    paymentMethodPlatform: any | undefined
  ): Promise<any> {
    let paymentMethod
    if (isPlatformPay === true) {
      paymentMethod = paymentMethodPlatform.paymentMethod
    } else {
      paymentMethod = this.paymentMethod
    }

    const data = {
      clientPriceOrders: this.tableContentService.getTotalOfSelectedContent(),
      gastroId: this.gastroService.$gastro.id,
      paymentMethodId: paymentMethod.id,
      positionIds: this.tableContentService.selectedTableContent.value.map(
        (position) => position.uuid
      ),
      tab: this.tableContentService.tab,
      tableId: this.tableService.getTable().id,
      taxObject: [],
      tips: this.$tipValue.valueAsNumber,
    }
    return data
  }

  /**
   * Handles a stripe paymentIntent
   * @param stripeData
   */
  private async handleStripePaymentIntent(stripeData: any) {
    if (stripeData.error) {
      // Show error from server on payment form
      throw stripeData.error
    } else if (stripeData.status === 'requires_action') {
      const { error } = await this.paymentService.stripe.confirmCardPayment(
        stripeData.client_secret
      )

      if (error) {
        // Show error from Stripe.js in payment form
        this.alertService.errorAlert(
          error.code,
          'Fehler',
          'Es gab einen Fehler mit deiner Karte. Überprüfe bitte die Daten und versuche es nochmal!'
        )
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
      // Swal.fire({
      // 	allowOutsideClick: false,
      // 	heightAuto: false,
      // 	// html: "Erfolgreich bezahlt.",
      // 	icon: "success",
      // 	imageHeight: 200,
      // 	imageUrl: "../assets/OBSplit-Logo.svg",
      // 	imageWidth: 200,
      // 	showConfirmButton: false,
      // 	timer: 4000,
      // 	title: "Erfolgreich bezahlt.",
      // });
    }
  }

  /**
   * Subscribes to the payment document found in
   * /gastro/{gastroId}/table/{tableId}/paymentIntents/{paymentDocId}
   * and listens for changes in the status field
   * @param paymentDocId
   */
  private async subscribeToPaymentDocument(paymentDocId: string) {
    this.alertService.updateLoadingForPayment('Zahlung wird verarbeitet')

    let timeout: NodeJS.Timeout

    return new Promise<boolean>((resolve) => {
      this.paymentDocumentSubscription = this.afs
        .collection('gastro')
        .doc(this.gastroService.$gastro.id)
        .collection('table')
        .doc(this.tableService.getTable().id)
        .collection('paymentIntents')
        .doc(paymentDocId)
        .valueChanges()
        .subscribe(async (paymentDoc) => {
          if (timeout !== undefined) {
            timeout = setTimeout(() => {
              Swal.close()
              this.paymentDocumentSubscription.unsubscribe()

              this.sentryService.captureError(
                'Timeout while waiting for payment to be accepted',
                {
                  gastroId: this.gastroService.$gastro.id,
                  paymentDoc: paymentDoc,
                  tableId: this.tableService.getTable().id,
                }
              )
              this.alertService.errorAlert(
                'Bitte überprüfen Sie ob der Betrag abgebucht wurde und melden sich im Zweifel bei <a href="mailto:info@split-app.de">info@split-app.de<a>',
                'Fehler',
                'Zeitüberschreitung der Anforderung'
              )
              resolve(false)
            }, 60000)
          }

          if (paymentDoc.status === PaymentState.PENDING) {
            return
          }

          if (
            paymentDoc.status === PaymentState.ACCEPTED ||
            paymentDoc.status === PaymentState.DECLINED
          ) {
            clearTimeout(timeout)
            this.paymentDocumentSubscription.unsubscribe()
            this.tableContentService.fetchOBTableData()
            resolve(paymentDoc.status === PaymentState.ACCEPTED)
          }

          if (paymentDoc.status === PaymentState.ACCEPTED) {
            this.alertService.updateLoadingForPaymentSuccess(
              'Zahlung erfolgreich'
            )
            this.$tipValue = { valueAsNumber: 0, valueAsString: '0.00' }
            this.tableContentService.selectedTableContent.next([])
            this.invoiceLink = paymentDoc.invoiceLink
            this.router.navigate(['receipt'])
          } else if (paymentDoc.status === PaymentState.DECLINED) {
            Swal.close()
            this.alertService.errorAlert('Die Zahlung wurde abgelehnt!')
          }
        })
    })
  }
}
