<ion-header class="header-content">
	<ion-row class="table-number-container">
		<ion-col text-center size="1">
			<ion-button fill="clear" (click)="goBack()">
				<ion-icon class="go-back" name="arrow-back-outline"></ion-icon>
			</ion-button>
		</ion-col>
		<ion-col text-center style="align-content: center; height: 100%; z-index: -10">
			<h1 class="table-number-text">{{this.tableNr}}</h1>
		</ion-col>
	</ion-row>
</ion-header>

<ion-content>
	<ion-row class="order-list-container">
		<ion-text *ngIf="this.partialPaymentMode == false" class="items-to-pay-text">Zu zahlende Artikel:</ion-text>
		<ion-text *ngIf="this.partialPaymentMode == true" class="unselect-text">Welche Artikel möchtest du
			bezahlen?</ion-text>
		<ion-row *ngFor="let order of this.tableContentService.aggregatedTableContent" style="width: 100%">
			<app-article (totalChanged)="updateTipsWithTotal()" style="width: 100%" [order]="order"
				[partialPay]="this.partialPaymentMode"></app-article>
		</ion-row>
	</ion-row>
</ion-content>

<ion-footer class="bottom-card">
	<ion-row class="bottom-big-text-container">
		<ion-row style="width: 100%">
			<ion-col>
				<ion-text class="bottom-big-text">Gesamtsumme: </ion-text>
			</ion-col>
			<ion-col style="text-align: end">
				<ion-text class="bottom-big-text">
					{{(isPaymentModeSelected ? calculateTotal() : totalAmountToPay) |
					currency: 'EUR':'symbol-narrow':'1.2-2':'de'}}
				</ion-text>
			</ion-col>
		</ion-row>
		<!-- Row for tipps -->
		<ion-row style="width: 100%" *ngIf="this.currentTip !== ''">
			<ion-col style="padding-top: 0">
				<ion-text class="bottom-small-text">inkl. Trinkgeld</ion-text>
			</ion-col>
			<ion-col style="padding-top: 0; text-align: end">
				<ion-text class="bottom-small-text">{{this.payAtTableOrderService.$tipValue.valueAsNumber | currency:
					'EUR':'symbol-narrow':'1.2-2':'de'}}</ion-text>
			</ion-col>
		</ion-row>

		<div *ngIf="this.isPaymentViewVisible == true" class="divider"></div>
	</ion-row>

	<!-- payment Selector -->
	<div *ngIf="isPaymentViewVisible == true">
		<ion-row class="payment-selection-container" (click)="openPaymentMethodModal()"
			*ngIf="paymentMethodSelected ==true">
			<!-- a wallet symbol or the payment method logo -->
			<ion-col *ngIf="this.selectedPaymentMethod.name == 'credit' "
				style="align-content: center;padding: 8.958px 10.196px 9.306px 11.046px;text-align:center;" size="3">
				<div class="payment-method-logo">
					<ion-img src="./../../assets/logo-selfcheckout-ec.svg"
						style="height: 25px; margin: 0 4px; width: 34px"></ion-img>
				</div>
			</ion-col>
			<ion-col *ngIf="this.selectedPaymentMethod.name == 'paypal' " style="align-content: center;text-align:center"
				size="3">
				<div class="payment-method-logo">
					<ion-img src="./../../assets/logo-selfcheckout-paypal.svg"
						style="height: 32px; margin: 0 4px; width: 35px"></ion-img>
				</div>
			</ion-col>
			<ion-col *ngIf="this.selectedPaymentMethod.label == 'ApplePay' " style="align-content: center;text-align:center"
				size="3">
				<div class="payment-method-logo">
					<ion-img src="./../../assets/logo-selfcheckout-appelpay.svg"
						style="height: 43px; margin: 0 4px; width: 61px"></ion-img>
				</div>
			</ion-col>
			<ion-col *ngIf="this.selectedPaymentMethod.label == 'Google Pay'" style="align-content: center;text-align:center"
				size="3">
				<div class="payment-method-logo">
					<ion-img src="./../../assets/logo-selfcheckout-googlepay.svg"
						style="height: 20px; margin: 0 4px; width: 43px"></ion-img>
				</div>
			</ion-col>
			<ion-col style="align-content: center;text-align: start;" size="7" *ngIf="paymentMethodSelected == true">
				<div class="payment-selection-choose">{{this.selectedPaymentMethod.label}}</div>
			</ion-col>

			<ion-col *ngIf="paymentMethodSelected == true" size="2" style="align-content: center; text-align: center">
				<ion-icon name="checkmark-outline" style="color: #36cc53; height: 30px; width: 30px"></ion-icon>
			</ion-col>
		</ion-row>

		<!--  tips selector -->
		<ion-row style="width: 100%">
			<ion-row style="width: 100%">
				<div class="tips-header">Trinkgeld</div>
				<div class="tips-header-optional">- optional</div>
			</ion-row>
			<ion-row style="width: 100%">
				<div class="tips-button-container">
					<button [ngClass]="this.currentTip == '10' ? 'tips-button' : 'tips-button-disabled'" name="options"
						(click)="handleTipChange('10')">
						<div class="heart animate-up"></div>
						<ion-col>
							<ion-row style="justify-content: center">
								<div class="tips-button-percentage"
									[ngClass]="this.currentTip == '10' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
									10%
								</div>
							</ion-row>
							<ion-row style="justify-content: center">
								<div class="tips-button-calculated"
									[ngClass]="this.currentTip == '10' ? 'tips-button-calculated' : 'tips-button-calculated-disabled'">
									{{this.calculatedTips.low | currency:
									'EUR':'symbol-narrow':'1.2-2':'de' }}
								</div>
							</ion-row>
						</ion-col>
					</button>
					<button [ngClass]="this.currentTip == '15' ? 'tips-button' : 'tips-button-disabled'" name="options"
						(click)="handleTipChange('15')">
						<div class="heart animate-up"></div>
						<ion-col>
							<ion-row style="justify-content: center">
								<div class="tips-button-percentage"
									[ngClass]="this.currentTip == '15' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
									15%
								</div>
							</ion-row>
							<ion-row style="justify-content: center">
								<div class="tips-button-calculated"
									[ngClass]="this.currentTip == '15' ? 'tips-button-calculated' : 'tips-button-calculated-disabled'">
									{{this.calculatedTips.medium | currency:
									'EUR':'symbol-narrow':'1.2-2':'de' }}
								</div>
							</ion-row>
						</ion-col>
					</button>
					<button [ngClass]="this.currentTip == '20' ? 'tips-button' : 'tips-button-disabled'" name="options"
						(click)="handleTipChange('20')">
						<div class="heart animate-up"></div>
						<ion-col>
							<ion-row style="justify-content: center">
								<div class="tips-button-percentage"
									[ngClass]="this.currentTip == '20' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
									20%
								</div>
							</ion-row>
							<ion-row style="justify-content: center">
								<div class="tips-button-calculated"
									[ngClass]="this.currentTip == '20' ? 'tips-button-calculated' : 'tips-button-calculated-disabled'">
									{{this.calculatedTips.high | currency:
									'EUR':'symbol-narrow':'1.2-2':'de' }}
								</div>
							</ion-row>
						</ion-col>
					</button>
					<button [ngClass]="this.currentTip == 'custom' ? 'tips-button' : 'tips-button-disabled'" name="options"
						(click)="openCustomTipModal()">
						<div *ngIf="this.customTipInput == '0.00'">
							<ion-row style="justify-content: center">
								<div
									[ngClass]="this.currentTip == 'custom' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
									Anderer
								</div>
							</ion-row>
							<ion-row style="justify-content: center">
								<div
									[ngClass]="this.currentTip == 'custom' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
									Betrag
								</div>
							</ion-row>
						</div>
						<div *ngIf="this.customTipInput !== '0.00'">
							<div
								[ngClass]="this.currentTip == 'custom' ? 'tips-button-percentage' : 'tips-button-percentage-disabled'">
								{{ this.customTipInput | currency:
								'EUR':'symbol-narrow':'1.2-2':'de' }}
							</div>
						</div>
					</button>
				</div>
			</ion-row>
		</ion-row>

		<ion-row class="pay-button-container" *ngIf="isPaymentViewVisible == true ">
			<ng-container *ngIf="this.paymentMethodSelected == false">
				<ion-button class="pay-button" (click)="this.openPaymentMethodModal()"> Zahlart wählen</ion-button>
			</ng-container>
			<ng-container *ngIf="(this.paymentMethodSelected == true && this.paymentService.$paymentId === 'credit')">
				<ion-button class="pay-button" (click)="this.payForSelectedTableContent()"> Zahlung abschließen</ion-button>
			</ng-container>
		</ion-row>
	</div>
	<ion-row class="pay-button-container" *ngIf="this.isPaymentViewVisible == false && this.partialPaymentMode == false">
		<ion-col size="4">
			<ion-button class="partial-pay-button" (click)="togglePartialPaymentMode()">
				Teilzahlung</ion-button>
		</ion-col>
		<ion-col size="8">
			<ion-button class="pay-button" (click)="fullPayment()">
				Alles Bezahlen</ion-button>
		</ion-col>
	</ion-row>

	<ion-row class="pay-button-container" *ngIf="this.partialPaymentMode == true && this.isPaymentViewVisible == false">
		<ion-col size="">
			<ion-button *ngIf="(tableContentService.selectedTableContent | async).length > 0" class="partial-pay-button-ready"
				(click)="openPaymentFooter()">
				Ausgewählte Artikel Bezahlen</ion-button>
			<ion-button *ngIf="(tableContentService.selectedTableContent | async).length === 0"
				class="partial-pay-button-not-ready" disabled>
				Ausgewählte Artikel Bezahlen</ion-button>
		</ion-col>
	</ion-row>
	<div *ngIf="this.paymentService.$paymentId === 'platform'" style="margin-top:8px; z-index: 2000"
		id="payment-request-button">
		<!-- A Stripe Element will be inserted here. -->
	</div>
</ion-footer>