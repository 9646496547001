import { animate, query, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TableContentService } from 'src/app/services/table-content.service'

@Component({
  animations: [
    trigger('horizontalGrowEffect', [
      transition(':enter', [
        style({
          height: '37px',
          width: '37px',
        }),
        animate('100ms ease-out', style({ width: '115px' })),
      ]),
      transition(':leave', [
        style({ width: '115px' }),
        query('svg', [
          style({ opacity: 1 }),
          animate('50ms ease', style({ opacity: 0 })),
        ]),
        animate(
          '100ms ease',
          style({
            height: '37px',
            width: '37px',
          })
        ),
      ]),
    ]),
  ],
  selector: 'app-article',
  styleUrls: ['./article.component.scss'],
  templateUrl: './article.component.html',
})
export class ArticleComponent {
  @Output() totalChanged = new EventEmitter<boolean>()
  @Input() order: any = {}
  @Input() partialPay: boolean

  showCollapsed = true
  showStepCounter = false
  total = '0,00'

  constructor(public tableContentService: TableContentService) {}

  ngOnInit() {
    this.order.partialCounter = this.order.partialCounter ?? 0
    this.tableContentService.selectedTableContent.subscribe((tableContent) => {
      if (this.partialPay === true) {
        this.order.partialCounter = tableContent.filter(
          (elem) =>
            elem.aggregationIdentifier === this.order.aggregationIdentifier
        ).length
        this.calcTotalWithChildren(true)
      }
    })
    this.calcTotalWithChildren(false)
  }

  // still ToDo until we split the article component into 2 separate ones
  // toggleExpandExtras(order) {
  // 	if (order.minimized == undefined) {
  // 		order.minimized = false;
  // 	}

  // 	order.minimized = !order.minimized;
  // }

  /**
   * Adds an item to the selectedTableContent
   *
   * @param {Object} item - The item to be added to the order.
   */
  addItemToPay(item) {
    if (this.partialPay) {
      if (item.partialCounter < item.counter) {
        this.tableContentService.addOrderToSelectedTableContent(item)
        this.totalChanged.emit(true)
      }
    }
  }

  /**
   * Removes an item from the selectedTableContent
   *
   * @param {Object} item - The item to be removed from the order.
   */
  removeItemToPay(item) {
    if (item.partialCounter > 0) {
      this.tableContentService.removeOrderFromSelectedTableContent(item)
      this.totalChanged.emit(true)
    }
  }

  /**
   * Toggles the display state of the step counter.
   *
   * @description
   * This function switches the boolean state of
   * `showStepCounter` between true and false.
   * It is used to show or hide the step counter in the UI.
   */
  toggleStepCounter() {
    if (this.showStepCounter) {
      this.showCollapsed = false
    }
    this.showStepCounter = !this.showStepCounter
  }

  calcTotalWithChildren(partial: boolean) {
    let total = 0

    total = total + this.order.price.amount / 100

    for (const child of this.order.children) {
      total = total + child.price.amount / 100
    }
    if (partial && this.order.partialCounter > 0) {
      total = total * this.order.partialCounter
    } else {
      total = total * this.order.counter
    }
    this.total = total.toFixed(2)
  }
  /**
   * To handle properly the horizontalGrowEffect animation
   */
  onAnimationDone() {
    if (!this.showStepCounter) {
      this.showCollapsed = true
    }
  }
}
